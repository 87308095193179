
/**
 *
 * */
export enum ApiVersion {
    /**
     * Currently, the only version.
     */
    V1 = 0,
}
